import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

// const responsive = {
//     superLargeDesktop: {
//         breakpoint: {max: 4000, min: 3000},
//         items: 3
//     },
//     desktop: {
//         breakpoint: {max: 3000, min: 1024},
//         items: 3
//     },
//     tablet: {
//         breakpoint: {max: 1024, min: 464},
//         items: 2
//     },
//     mobile: {
//         breakpoint: {max: 464, min: 0},
//         items: 1
//     }
// }
const {useRef, useState, useEffect} = React;

const LazyImage = ({src, alt, title}) => {
    const rootRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <div className="tech_items">
            <div className="tech_img">
                <img src={`${mediaBaseURL}${isVisible ? src : loading}`} ref={rootRef}
                     className="img-fluid" alt={alt}/>
            </div>
            <div className="tech_txt">
                <h5>{alt} <span>{title}</span></h5>
            </div>
        </div>
    );
};

function Techstack({data}) {
    return (
        <div className="techstack_section p-100">
            <Container>
                <Row>
                    <Col xs={12} md={10} lg={6} xl={6} xxl={6} className="m-auto text-center">
                        <h2>{checkData(data, 'tech_stack_title')}</h2>
                        <p> {checkData(data, 'tech_stack_description')}</p>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="my-auto text-center">
                        <Row>
                            {data?.tech_stack_used?.map((value, i) => (
                                <Col key={i} xs={12} md={4} lg={4} xl={4} xxl={4}>
                                    <LazyImage
                                        alt={value.title}
                                        src={value.image} key={i} title={value.tech_name}
                                    />

                                </Col>

                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Techstack;